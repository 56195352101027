$backgroundColor: #533895;
$dividerColor: #482E86;

.sider-mobile {
    height: 100vh;


    .ant-drawer-header {
        background-color: $backgroundColor;
        border-bottom: 1px solid $dividerColor;

        .ant-drawer-header-title {
            display: flex;
            flex-direction: row-reverse;

            .logo-block {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 20px;
            }

            .ant-drawer-close {
                .anticon {
                    color: #fff
                }
            }
        }
    }

    .ant-drawer-body {
        padding: 0;
        background-color: $backgroundColor;
        color: #fff;
        font-size: 16px;

        .user-mail-sidebar {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 16px 0 16px 20px;
            border-bottom: 1px solid $dividerColor;

            .icon {
                color: #fff;
            }

            .text {
                margin-left: 10px;
            }

        }

        .nav-menu-mobile {
            border-right: none;
            background-color: $backgroundColor;
            padding: 16px;
            border-bottom: 1px solid $dividerColor;

            .nav-item {
                color: #fff;
                font-size: 16px;
            }

            .ant-menu-item-selected {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 2px;
            }
        }

        .nav-info-block {
            display: flex;
            flex-direction: column;
            padding: 24px 36px;

            .nav-item {
                color: #fff;
                font-size: 16px;
                margin-bottom: 24px;
            }
        }
    }

    .ant-drawer-footer {
        background-color: $backgroundColor;
        border-top: 1px solid $dividerColor;
        padding: 0 0 77px;

        .change-password-block {
            padding: 25px 22px;
            border-bottom: 1px solid $dividerColor;
            border-top: 1px solid $dividerColor;
            font-size: 16px;

            .text {
                color: #fff;
                margin-left: 10px;
            }
        }

        .logout-block {
            padding: 25px 7px;

            .logout-btn-mobile {
                color: #fff;
                font-size: 16px;
            }
        }

    }
}


.header {
    display: flex;
    justify-content: space-between;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.12);

    .trigger {
        font-size: 18px;
        margin-top: 12px;
    }

    .header-menu {
        flex-grow: 1;
        display: flex;

        .header-menu-nav {
            margin-left: 45px;
            border-bottom-width: 0px;
            flex-grow: 1;
        }
    }

    .info-block-desktop {
        display: inline;

        .user-info-dropdown {
            cursor: pointer;
        }

        .user-info-dropdown:hover {
            color: $backgroundColor;
        }
    }
}

@media screen and (max-width: 576px) {
    .header-container {
        padding-left: 20px;
    }
}