.public-page-container {
    min-height: 100vh;

    .sider-container {
        position: relative;
        background: linear-gradient(180deg, #EDAC61 0%, #EC854D 100%);
        // padding: 0 60px 0 100px;

        .logo {
            position: absolute;
            left: 60px;
            top: 30px;
            width: 157px;
        }

        .sider-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .sider-description {
                padding: 0 100px 0 60px;
                font-family: 'Helvetica';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0291429px;
                color: #FFFFFF;
                margin-top: 100px;
            }
        }
    }

    .header-container {
        display: none;
        box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.12);
    }

    .footer-container {
        justify-content: center;
        padding-bottom: 70px;
        padding-right: 22%;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.0426667px;
        color: #D9D9D9;
    }
}

@media screen and (max-width: 992px) {
    .public-page-container {
        .sider-container {
            display: none;
        }

        .header-container {
            display: block;
        }

        .footer-container {
            padding-right: 50px;
        }
    }
}

@media screen and (max-width: 575px) {

    .footer-container {
        //   padding: 0 20px;
    }
}